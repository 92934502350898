@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fade-in {
	animation: fade-in 0.3s ease-in-out;
}

.ant-select-selector {
	height: 41px !important;
	border-color: #6b7280 !important;
}
.ant-select-selection-search-input:focus {
	outline: none !important;
	border: none !important;
	box-shadow: none !important;
	/* margin-top: 5px !important; */
}

.ant-select-selection-item {
	margin-top: 5px !important;
	font-size: 14px !important;
}
.ant-select-selection-search-input {
	height: 41px !important;
}
.ant-select-selection-placeholder {
	margin-top: 5px !important;
}

.quill .ql-container,
.ql-editor {
	min-height: 200px;
}
.filter-CV-list .ant-select-selector {
	width: 220px !important;
}

.grecaptcha-badge {
	visibility: hidden;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
	background-color: #dfe0e3 !important;
}
.react-pdf__Page {
	overflow: hidden !important;
	height: 890px !important;
	background-color: #eaeaea !important;
	padding: 20px 0;
	margin-top: 20px;
}

.react-pdf__Page__canvas {
	margin: 0 auto;
}

.react-pdf__Page__textContent {
	display: none;
}
.cost {
	background-color: rgb(221, 254, 221) !important;
}
.clone-job {
	background-color: rgb(246, 255, 207) !important;
}

.input[type='number'],
input[type='password'],
input[type='text'] {
	height: 40px !important;
}

.hide {
	max-height: 33%;

	p:not(:first-child) {
		display: none;
	}
}

.see {
	max-height: 100%;
}
